body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-top: 36px;
  margin-left: 2px; /* gutter size offset */
  width: 1142px; /* full width minus padding */
  @media (max-width: 1200px) {
    width: 738px;
  }
  @media (max-width: 950px) {
    width: 358px;
  }
}

.masonry-grid_column {
  background-clip: padding-box;
}

/* Style your items */
.masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 24px;
}

.nav-skip {
  cursor: pointer;
}
.nav-skip  a {
    position: absolute;
    left: -999px;
    top: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -2;

    &:focus, &:active {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      width: auto;
      height: auto;
      overflow: auto;
      padding: 24px;
      font-size: 18px;
      text-align: center;
      text-decoration: underline;
      background: #E6E6E6;
      color: #0067B8;
      outline: none;
      z-index: 10000;
    }

    &:focus {
      border: 1px dashed #000;
    }
  }
